.projectsContainer {
  height: 100%;
  padding: 100px 30px;
}
.projectsContainer h1 {
  font-size: min(4rem, 10vw);
  text-align: center;
  margin: 6px 0;
}

.projectsContainer p {
  font-size: 1.5rem;
  margin: 0;
}
.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  justify-items: center;
  gap: 30px;
}

@media (max-width: 850px) {
  .projectsContainer {
    padding: 80px 15px;
    max-width: 90%;
    margin: 0 auto;
  }

  .projects {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
