:root {
  --nav-background-color: #c9aaa6;
  --nav-text-color: #2f234f;
  --nav-text-color-active: #574c4c;
}

.container {
  display: flex;
  align-items: center;
  padding: 0;
  height: 100%;
}

.navbar {
  height: 60px;
  width: 100%;
  background-color: var(--nav-background-color);
  position: absolute;
}

.github-icon {
  height: 40px;
  width: 40px;
}
.github-icon-container {
  /* Pin the icon to the top right corner of the navbar. */
  position: absolute;
  top: 10px;
  right: 10px;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 50px;
}

.nav-elements ul a {
  font-size: 20px;
  font-weight: 400;
  color: var(--nav-text-color);
  text-decoration: none;
}

.nav-elements ul a.active {
  color: var(--nav-text-color-active);
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--nav-text-color-active);
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .container {
    padding-left: 15px;
  }

  .nav-elements {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 60px;
    background-color: var(--nav-background-color);
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
