h1 {
  display: inline-block;
  transition: font-size 0.5s;
  vertical-align: middle;
  letter-spacing: 2px;
  margin: auto;
  color: antiquewhite;
  font-family: "Roboto", sans-serif;
  font-size: 6rem;
  height: 6rem;
  margin-bottom: 25px;
  white-space: nowrap;
}

@media (max-width: 600px) {
  h1 {
    font-size: 4rem;
    height: 4rem;
    margin-bottom: 15px;
  }
}
/* The parent element of the typewriter */
.typewriter {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  height: 50%;

  /*background-color: rgba(100, 0, 0, 0.5);*/
}

#cursor {
  vertical-align: middle;
  display: inline-block;
  /* Add a cursor that is directly in front of the text, moves with it, and is centered vertically with it. */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  left: 0;
  width: 5px;
  outline: black solid 1px;
  background-color: antiquewhite;
  animation: blink 0.75s step-end infinite;
}

@keyframes blink {
  from,
  to {
    outline: transparent solid 1px;
    background-color: transparent;
  }
  50% {
    outline: black solid 1px;
    background-color: antiquewhite;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    outline: transparent solid 1px;
    background-color: transparent;
  }
  50% {
    outline: black solid 1px;
    background-color: antiquewhite;
  }
}
