.NotFound {
  /* center in the middle of the screen */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.NotFound h2 {
  margin: 0;
  margin-bottom: 50px;
}
