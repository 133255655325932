html,
body {
  text-align: center;
  background-color: black;
  width: 100%;
  padding: 0;
  margin: 0;
  max-width: 100%;
  background-image: radial-gradient(#372549, #1a1423);
  background-attachment: fixed;
  background-repeat: no-repeat;
  scroll-behavior: smooth;
  font-family: "Roboto", sans-serif;
}

h2 {
  color: #eacdc2;
  transition: font-size 0.5s;
  font-family: "Roboto", sans-serif;
  font-size: calc(4vh + 7px);
  margin-bottom: 20px;
}
h3 {
  color: #eacdc2;
  font-family: "Roboto", sans-serif;
  font-size: calc(4vh + 10px);
}
p {
  font-family: "Roboto", sans-serif;
  color: snow;
  font-size: calc(2vh + 8px);
}

.btn-link {
  transition: transform 0.25s;
  background-color: #774c60;
  border: 2px solid #634050;
  padding: 15px 40px;
  text-decoration: none;
  color: snow;
  font-family: "Roboto", sans-serif;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 15px;
  font-size: 1.1rem;
  position: relative;
}
