.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Put the buttons contained in .buttons in a 2x2 grid that can can be
 * rearranged to a 1x4 grid on smaller screens, while making sure each .btn-link can't get larger than 300px. */
.buttons {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
  max-width: 800px;
  width: 100%;
  margin: 20px 0;
}

/* Make sure the buttons are centered on smaller screens. */
@media (max-width: 600px) {
  .landing {
    padding-top: 50px;
  }
  .buttons {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
}
