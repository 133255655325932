.projectContents {
  display: flex;
  justify-content: space-around;
}

.projectLinks {
  flex-shrink: 0;
  text-align: center;
}
.projectLink {
  color: snow;
  margin-top: 0;
  margin-bottom: 30px;
}

.projectLinkList {
  margin: 0;
  display: inline-block;
  padding-left: 0;
}
.projectImages {
  padding-right: 30px;
}
.projectImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project p {
  margin-bottom: 15px !important;
}
