.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.contact h3 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 50px;
}
.contact {
  max-height: 80%;
}

.contactText {
  padding: 0 150px;
}

@media (max-width: 600px) {
  .contactText {
    padding: 0 30px;
  }
}

.contact a {
  color: snow;
}
